import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Host,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ControlContainer, FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config';
import { Customer, CustomerService } from '@klickdata/core/customer';
import { FormHelper } from '@klickdata/core/form';
import { MediaService } from '@klickdata/core/media/src/media.service';
import { S3MediaService } from '@klickdata/core/media/src/s3-media.service';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { AppScope, Resource, ResourceService, ResourceTypes } from '@klickdata/core/resource';
import { ResourceItemData, ResourceItem } from '@klickdata/core/resource-item';
import { ResourceStaffRoles } from '@klickdata/core/resource/src/types.enum';
import { User } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { DialogDisplayImgComponent } from 'apps/klickdata/src/app/shared/dialog/dialog-display-img/dialog-display-img.component';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    first,
    map,
    share,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { CourseManagerCoreComponent } from '../course-manager-core.component';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Currency } from '@klickdata/core/resource/src/resource.model';

@Component({
    selector: 'app-course-manager-core-secodary-controls',
    templateUrl: './course-manager-core-secodary-controls.component.html',
    styleUrls: ['./course-manager-core-secodary-controls.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class CourseManagerCoreSecodaryControlsComponent implements OnInit, OnDestroy, OnChanges {
    @Input() resource: Resource;
    public itemsHaveTests: boolean;
    public showPublisherMediaUploader: boolean;
    public mandatoryTestResourceItems: Observable<ResourceItemData[]>;
    @ViewChild('uploadInput') uploadInput: ElementRef;
    @ViewChild('agendaExpansionPanel') agendaExpansionPanel: MatExpansionPanel;
    @ViewChild('occasionExpansionPanel') occasionExpansionPanel: MatExpansionPanel;
    public resourceForm: FormGroup;
    public defaultResourceSummaryCount: number;
    public defaultResourceOnlineEventId: number;
    public maxResourceInstructors: number;
    public defaultCourseGoalCount: number;
    public isArticleCodeValid: Observable<boolean>;
    ResourceStaffRoles = ResourceStaffRoles;
    public currencies: Currency[];
    public saving: boolean;
    public playTimeOptions = {
        zeroValue: null,
        showPreview: false,
        showYears: false,
        showMonths: false,
        showDays: false,
        showWeeks: false,
    };
    public durationOptions = {
        zeroValue: null,
        showPreview: false,
        showYears: false,
        showSeconds: false,
    };
    vaildArticleCode: Observable<number>;
    public instructorUploading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    AppScope = AppScope;
    ResourceTypes = ResourceTypes;
    public destroy: Subject<boolean> = new Subject<boolean>();
    public customer$: Observable<Customer>;
    public user$: Observable<User>;
    public progress$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    public unlimited_seats = new FormControl(true);
    public enabledPassingTest = new FormControl(false);

    constructor(
        @Host() protected host: CourseManagerCoreComponent,
        protected parentFormDirective: FormGroupDirective,
        protected configService: ConfigService,
        protected cdRef: ChangeDetectorRef,
        protected dialog: MatDialog,
        protected fb: FormBuilder,
        protected resourceService: ResourceService,
        protected mobileService: MobileService,
        protected router: Router,
        protected auth: AuthService,
        protected s3MediaService: S3MediaService,
        protected mediaService: MediaService,
        protected customerService: CustomerService
    ) {
        this.defaultResourceSummaryCount = this.configService.config.defaultResourceSummaryCharCount;
        this.defaultCourseGoalCount = this.configService.config.defaultResourceGoalCharCount;
        this.defaultResourceOnlineEventId = this.configService.config.defaultResourceOnlineEventId;
        this.maxResourceInstructors = this.configService.config.maxResourceInstructors;
        this.resourceForm = parentFormDirective.form;
        this.buildForm(this.resourceForm);
        this.currencies = Utils.getAllCurrencies();
    }

    ngOnInit(): void {
        this.vaildArticleCode = this.resourceForm.get('article_code').valueChanges.pipe(
            filter((query) => !!query && typeof query === 'string'),
            debounceTime(300),
            distinctUntilChanged(),
            switchMap((query) => {
                if (query) {
                    return this.resourceService.checkFieldValidaty({ article_code: query, id: this.resource?.id });
                }
            }),
            map((res) => (res ? 1 : -1)),
            share(),
            tap((isValidCode) =>
                this.resourceForm.get('article_code').setErrors(isValidCode === -1 ? { incorrect: true } : null)
            ),
            takeUntil(this.destroy)
        );

        this.unlimited_seats.valueChanges.pipe(takeUntil(this.destroy)).subscribe((unlimited) => {
            if (unlimited) {
                this.resourceForm.get('total_seats').setValue(0);
                this.resourceForm.get('total_seats').disable();
            } else {
                this.resourceForm.get('total_seats').enable();
            }
            this.resourceForm.get('total_seats').markAsDirty();
        });

        this.resourceForm
            .get('medias.publisher')
            ?.valueChanges.pipe(
                filter((val) => !!val),
                takeUntil(this.destroy)
            )
            .subscribe(() => {
                this.showPublisherMediaUploader = false;
                this.cdRef.markForCheck();
            });

        this.resourceForm
            .get('items')
            ?.valueChanges.pipe(
                filter((val) => !!val),
                takeUntil(this.destroy)
            )
            .subscribe(() => {
                this.updateScoresTests();
                this.cdRef.markForCheck();
            });

        this.mandatoryTestResourceItems = this.resourceForm.get('items').valueChanges.pipe(
            takeUntil(this.destroy),
            filter((items) => !!items),
            map((items) => items.filter((item) => item.mandatory && item.item_type_value === 'test')),
            tap((items) => {
                if (items.length !== 0) {
                    this.itemsHaveTests = true;
                    if (!this.resourceForm.get('examination')?.value.final_test_id) {
                        this.resourceForm
                            .get('examination')
                            .patchValue({ final_test_id: items[items.length - 1].child_resource_id });
                    }
                }
                this.cdRef.markForCheck();
            })
        );

        this.customer$ = this.auth.getCustomer().pipe(first());
        this.user$ = this.auth.getUser().pipe(first());
    }
    onUploadComplete(ev: any) {}
    ngOnChanges(changes: SimpleChanges): void {
        if (this.resource) {
            this.updateForm();
        }
    }
    public onTabSelectionChange(activeIndex: number) {
        this.agendaExpansionPanel?.close();
        this.occasionExpansionPanel?.close();
    }
    public toggleLoading(ev: boolean) {
        ev ? this.instructorUploading.next(true) : this.instructorUploading.next(false);
    }
    public showImgModal(id: number) {
        this.dialog.open(DialogDisplayImgComponent, {
            maxWidth: '70%',
            maxHeight: '50vh',
            data: id,
        });
    }
    private buildForm(resForm: FormGroup) {
        resForm.addControl('goal', new FormControl(''));
        resForm.addControl('time_limit', new FormControl(''));
        resForm.addControl('duration', new FormControl(''));
        resForm.addControl('other_info', new FormControl(''));
        resForm.addControl('price', new FormControl(''));
        resForm.addControl('promo_code', new FormControl(''));
        resForm.addControl('finish_msg', new FormControl());
        resForm.addControl('article_code', new FormControl(''));
        resForm.addControl('sorting_code', new FormControl(0));
        resForm.addControl('subtitles', new FormControl(''));
        resForm.addControl('show_publisher_logo', new FormControl(false));
        resForm.addControl('total_seats', new FormControl({ value: 0, disabled: true }));
        resForm.addControl('audience', new FormControl(''));
        resForm.addControl('pre_skills', new FormControl(''));
        resForm.addControl('agenda', new FormControl(''));
        // resForm.addControl('episode', new FormControl(''));
        resForm.addControl('extra_seats', new FormControl(''));
        resForm.addControl('copyrights', new FormControl(''));
        resForm.addControl('priority', new FormControl(''));
        resForm.addControl('always_available', new FormControl(true));
        resForm.addControl('start_date', new FormControl(''));
        resForm.addControl('end_date', new FormControl(''));
        resForm.addControl(
            'medias',
            this.fb.group({
                background: [],
                screenshot: [],
                publisher: [],
                exercise: [],
            })
        );
        resForm.addControl(
            'occasion_dates',
            this.fb.array([
                this.fb.group({
                    id: [''],
                    start_date: [moment()],
                    end_date: [moment().add(1, 'd')],
                }),
            ])
        );
        resForm.addControl(
            'passing_levels',
            this.fb.array([
                // this.fb.group({
                //     item_id: [''],
                //     score: [],
                // }),
            ])
        );

        resForm.addControl(
            'examination',
            this.fb.group({
                type: ['Participation'],
                grade_system_id: [],
                final_test_id: [],
                diploma_level: [],
            })
        );
        resForm.addControl(
            'collaboration',
            this.fb.group({
                view: this.fb.group({
                    status: [false],
                    exclude: [false],
                }),
                mandatory: this.fb.group({
                    status: [false],
                    exclude: [false],
                }),
                enroll_approval_required: this.fb.group({
                    status: [false],
                    exclude: [false],
                }),
                signoff_required: this.fb.group({
                    status: [false],
                    exclude: [false],
                }),
            })
        );
    }

    private updateForm() {
        if (this.resource.duration) {
            this.resourceForm.patchValue({
                duration: moment.duration({ s: <number>this.resource.duration }).toISOString(),
            });
            FormHelper.resetForm(this.resourceForm.get('duration'));
        }
        if (this.resource.time_limit) {
            this.resourceForm.patchValue({
                time_limit: moment.duration({ s: <number>this.resource.time_limit }).toISOString(),
            });
            FormHelper.resetForm(this.resourceForm.get('time_limit'));
        }
        if (this.resource.occasion_dates?.length) {
            this.updateCourseOccasions(this.resource.occasion_dates);
            FormHelper.resetForm(this.resourceForm.get('occasion_dates'));
        }
        this.updateScoresTests();
    }
    updateCourseOccasions(occasions: { start_date: string; end_date: string }[]) {
        let occFGs: FormGroup[] = [];
        occFGs = occasions.map((date: { id: number; start_date: string; end_date: string }) => this.fb.group(date));
        const occasionsFormArray = this.fb.array(occFGs);
        this.resourceForm.setControl('occasion_dates', occasionsFormArray);
    }
    get courseOccasions(): FormArray {
        return this.resourceForm.get('occasion_dates') as FormArray;
    }
    get passingScoresArray(): FormArray {
        return this.resourceForm.get('passing_levels') as FormArray;
    }
    get testItems(): ResourceItem[] {
        return (<ResourceItem[]>this.resourceForm.value.items).filter(
            (item) => item.item_type_value == 'test' && !!item.id
        );
    }
    private updateScoresTests() {
        if (this.testItems?.length < 1) {
            this.resourceForm.get('passing_levels').patchValue(null);
            return;
        }
        this.testItems.forEach((test) => {
            const existingItem = this.passingScoresArray.controls.find((control) => control.value.item_id === test.id);
            if (!existingItem) {
                this.passingScoresArray.push(
                    this.fb.group({
                        item_id: [test.id],
                        percentage: [this.getTestItemFromResourceScores(test.id)?.percentage ?? 75],
                        repeats: [this.resource?.id ? this.getTestItemFromResourceScores(test.id)?.repeats : null],
                        enabled: [
                            (this.getTestItemFromResourceScores(test.id)?.percentage ||
                                this.getTestItemFromResourceScores(test.id)?.repeats) ??
                                false,
                        ],
                    })
                );
            }
        });
    }
    getTestItemById(id: number): ResourceItem {
        return this.testItems.find((item) => item.id == id);
    }
    getTestItemFromResourceScores(id: number) {
        return !!this.resource?.passing_levels?.length
            ? this.resource.passing_levels?.find((item) => item.item_id == id)
            : null;
    }
    addDate() {
        this.courseOccasions.push(
            this.fb.group({
                start_date: [moment()],
                end_date: [moment().hour(0).minute(0).second(0).add(1, 'd')],
            })
        );
        this.resourceForm.get('occasion_dates').markAsDirty();
    }
    removeDate(i: number) {
        const control = <FormArray>this.resourceForm.controls['occasion_dates'];
        control.removeAt(i);
        this.resourceForm.get('occasion_dates').markAsDirty();
    }
    onTypeChange() {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
            data: {
                icon: 'group_work',
                title: $localize`Can't change course type`,
                contentBody: $localize`If you want to create onsite, hybrid  or blended course. It'll be event. Event is the type that you can manage classroom, webinar, interview or conference in KLMS.`,
                positiveBtn: $localize`Create an event`,
                negativeBtn: $localize`Cancel`,
            },
        });
        this.mobileService
            .getSideNavAction()
            .pipe(filter((action) => action === SideNaveActionsTypes.POSITIVE))
            .subscribe((action) => {
                if (action) {
                    this.router.navigate(['/admin/content/events/create']);
                }
            });
    }
    public fileHandler(event: any, mediaControlName: string) {
        const file = event.target.files[0];
        if (!file) {
            return;
        }
        this.s3MediaService
            .uploadMediaToS3(file, this.progress$)
            .pipe(
                takeUntil(this.destroy),
                switchMap((media) =>
                    this.mediaService.uploadMediaByLink({
                        ...media.getData(),
                        scope_id: AppScope.RESOURCES,
                    })
                )
            )
            .subscribe(
                (media) => {
                    this.saving = false;
                    if (media && media.id) {
                        this.resourceForm.get('medias').get(mediaControlName).patchValue([media.id]);
                        FormHelper.markForm(this.resourceForm);
                        this.progress$.next(null);
                        this.cdRef.markForCheck();
                    }
                },
                (err) => {} // TODO on error
            );
    }

    editResourceAccessPermision(permType: string, value: any) {
        const sortingDir = value?.status && value?.exclude ? 'asc' : 'desc';

        this.host
            .editResourceAccessPermision(permType, sortingDir)
            .pipe(takeUntil(this.destroy))
            .subscribe(() => this.cdRef.markForCheck());
    }
    public updateResRecurring(customerId: number) {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.UPDATE_RECURRING,
            data: {
                title: $localize`Update recurring`,
                resource: this.resource,
                type: 'updateRecurringResource',
                positiveBtn: $localize`Update`,
                negativeBtn: $localize`Cancel`,
            },
        });
        this.mobileService
            .getSideNavResponseData()
            .pipe(
                filter((data) => data.type == 'updateRecurringResource'),
                switchMap((data: any) =>
                    this.customerService.update({
                        id: customerId,
                        recurring: { resource_id: this.resource.id, metadata: data.value },
                    })
                )
            )
            .subscribe();
    }
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}

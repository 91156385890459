<form
    #formDirective="ngForm"
    [formGroup]="resourceForm"
    (ngSubmit)="$event === ResourceManagerEvent.SUBMIT && resourceComposer.submit()"
    class="wrapper-parent column-third-layout"
    fxFlex="100"
    fxLayout="row wrap"
    fxLayout.lt-md="column"
>
    <div fxLayout="row wrap" fxFlex="100%" fxLayoutAlign="space-between stretch">
        <div
            fxFlex="0 0 70%"
            fxFlex.lt-md="100%"
            class="main-row res-display"
            fxLayout="row wrap"
            fxLayoutAlign="start start"
        >
            <div fxLayout="row" fxLayoutAlign="end center" class="tools-wrapper">
                <button fxHide.gt-sm mat-icon-button type="button" (click)="list()" i18n-matTooltip matTooltip="View">
                    <mat-icon>list</mat-icon>
                </button>
                <button
                    fxHide.gt-sm
                    [ngClass]="{ 'is-disabled': !resource?.id }"
                    mat-icon-button
                    type="button"
                    (click)="review()"
                    i18n-matTooltip
                    matTooltip="View"
                >
                    <mat-icon>visibility</mat-icon>
                </button>
                <button
                    *ngIf="!(loadingUploader | async)"
                    [ngClass]="{
                        'no-media': !resourceForm.value.media_id,
                        'blue-ice': (isAIUploadedImg | async),
                        green: !(isAIUploadedImg | async)
                    }"
                    mat-icon-button
                    type="button"
                    (click)="addMedia()"
                    i18n-matTooltip
                    [matTooltip]="!resourceForm.value.media_id ? 'Add cover image' : 'Show cover image'"
                >
                    <mat-icon>add_photo_alternate</mat-icon>
                </button>
                <app-kd-logo-loading *ngIf="loadingUploader | async" size="small"></app-kd-logo-loading>
                <app-media
                    fxHide
                    fxFlex="0 0 auto"
                    [simple]="true"
                    formControlName="media_id"
                    [scope]="ResourceTypes.scopeByType(typeId)"
                    dropLabel="Drop the image here"
                    (saving)="loadingUploader.next($event)"
                    i18n-dropLabel
                ></app-media>
            </div>
            <mat-tab-group
                #mainTabs
                fxFlex="grow"
                class="main-creator-tabs"
                (selectedTabChange)="onTabSelectionChange($event.index)"
            >
                <mat-tab #mainTab label="Content" i18n-label style="max-height: 100%">
                    <div
                        class="wrapper-parent column-third-layout"
                        fxFlex="100"
                        fxLayout="row wrap"
                        fxLayout.lt-md="column"
                    >
                        <!-- EB asked to prevent showing cover image in first tab and keep only in extra tab on 20230203-11:08 -->
                        <div fxHide class="w-100" fxLayout="row">
                            <ng-container [ngTemplateOutlet]="coverImg"></ng-container>
                        </div>
                        <div
                            fxFlex="0 0 100%"
                            fxFlex.lt-md="100%"
                            class="main-row res-display"
                            fxLayout="column"
                            fxLayoutAlign="start center"
                        >
                            <mat-form-field class="w-100 text-bold clear-consider editor-title" fxFlex="grow">
                                <textarea
                                    #title
                                    matInput
                                    type="text"
                                    formControlName="title"
                                    [placeholder]="getFieldLabel('title', true)"
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="1"
                                    cdkAutosizeMaxRows="10"
                                    required
                                ></textarea>
                                <button
                                    *ngIf="title.value.length"
                                    mat-icon-button
                                    class="clear-btn"
                                    matSuffix
                                    type="button"
                                    (click)="$event.stopPropagation(); clearField('title'); title.focus()"
                                >
                                    <mat-icon>close</mat-icon>
                                </button>
                                <mat-error i18n>Title field is required!</mat-error>
                            </mat-form-field>

                            <div class="editor-wrapper editor-bullets" fxLayout="column" fxLayoutAlign="start start">
                                <mat-form-field
                                    [floatLabel]="'never'"
                                    class="summary-field limit-drag clear-consider"
                                    fxFlex="0 1 100%"
                                >
                                    <textarea
                                        #summary
                                        matInput
                                        [placeholder]="getFieldLabel('bullets', true)"
                                        minRows="2"
                                        cdkTextareaAutosize
                                        #autosize="cdkTextareaAutosize"
                                        cdkAutosizeMinRows="1"
                                        cdkAutosizeMaxRows="10"
                                        [maxLength]="defaultResourceSummaryCount"
                                        formControlName="bullets"
                                    ></textarea>

                                    <button
                                        *ngIf="summary.value.length"
                                        mat-icon-button
                                        class="clear-summary"
                                        matSuffix
                                        type="button"
                                        (click)="$event.stopPropagation(); clearField('bullets'); summary.focus()"
                                    >
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    <div class="bullets-info-wrapper">
                                        <span
                                            [matTooltip]="getFieldLabel('bullets', false)"
                                            *ngIf="summary.value"
                                            class="char-count"
                                        >
                                            {{ summary.value.length }} /
                                            {{ defaultResourceSummaryCount - summary.value.length }}
                                            <!-- <span>(</span>
                                            {{ defaultResourceSummaryCount - summary.value.length }}
                                            <span i18n="@@left">left</span>
                                            <span>)</span> -->
                                        </span>
                                        <!-- <mat-icon
                                            class="third-colmn-editor-title-icon"
                                            [matTooltip]="getFieldLabel('bullets', false)"
                                            (click)="showMobileToolTip('bullets')"
                                            i18n-matTooltip
                                            >question_mark</mat-icon
                                        > -->
                                    </div>
                                </mat-form-field>
                            </div>
                            <ng-container [ngSwitch]="typeId">
                                <ng-container *ngSwitchCase="ResourceTypes.GeneralCoursePlan">
                                    <app-course-manager-core
                                        class="w-100"
                                        #compRef
                                        [(resource)]="resource"
                                        (saved)="saved.emit(true)"
                                        (loading)="loading = $event"
                                        (onResKeysValidatyChanges)="resKeysValidaty = $event"
                                    ></app-course-manager-core>
                                </ng-container>
                                <ng-container *ngSwitchCase="ResourceTypes.EVENT">
                                    <app-event-manager-core
                                        class="w-100"
                                        #compRef
                                        [(resource)]="resource"
                                        [active]="mainTab.isActive"
                                        (saved)="saved.emit(true)"
                                        (loading)="loading = $event"
                                        (onResKeysValidatyChanges)="resKeysValidaty = $event"
                                    ></app-event-manager-core>
                                </ng-container>
                                <ng-container *ngSwitchCase="ResourceTypes.MATERIAL">
                                    <app-materials-manager-core
                                        #compRef
                                        class="w-100"
                                        [(resource)]="resource"
                                        [active]="mainTab.isActive"
                                        (saved)="saved.emit(true)"
                                        (loading)="loading = $event"
                                        (onResKeysValidatyChanges)="resKeysValidaty = $event"
                                    ></app-materials-manager-core>
                                </ng-container>
                                <ng-container *ngSwitchCase="ResourceTypes.GeneralTest">
                                    <app-test-manager-core
                                        #compRef
                                        class="w-100"
                                        [(resource)]="resource"
                                        [bottomToolSheet]="!resource"
                                        (saved)="saved.emit(true)"
                                        (loading)="loading = $event"
                                        (onResKeysValidatyChanges)="resKeysValidaty = $event"
                                    >
                                    </app-test-manager-core>
                                </ng-container>
                                <ng-container *ngSwitchCase="ResourceTypes.GeneralSurvey">
                                    <app-survey-manager-core
                                        #compRef
                                        class="w-100"
                                        [(resource)]="resource"
                                        [bottomToolSheet]="!resource"
                                        (saved)="saved.emit(true)"
                                        (loading)="loading = $event"
                                        (onResKeysValidatyChanges)="resKeysValidaty = $event"
                                    >
                                    </app-survey-manager-core>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab #extraTab label="Extra" i18n-label style="max-height: 100%">
                    <div *ngIf="!!resourceForm.value.media_id" class="image-wrapper extra-tab-img">
                        <mat-expansion-panel
                            [expanded]="true"
                            #coverImagePanel
                            class="user-manage-course-expantion res-creat-manag"
                        >
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon>add_photo_alternate</mat-icon>
                                    <div i18n="@@coverImg">Cover image</div>
                                    <mat-icon
                                        class="icon-explain"
                                        matTooltip="It's the image to be displayed in the cover"
                                        i18n-matTooltip
                                        >help_outline</mat-icon
                                    >
                                </mat-panel-title>
                                <mat-panel-description fxLayoutAlign="end center">
                                    <span
                                        matTooltip="Indicator of field change"
                                        i18n-matTooltip
                                        class="cell-indicator"
                                        [ngClass]="{ green: resourceForm.get('media_id').dirty }"
                                    ></span>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div class="w-100" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px">
                                <ng-container [ngTemplateOutlet]="coverImg"></ng-container>
                                <div class="ai-prompt-text" *ngIf="mediaAIPrompterText.value">
                                    <span i18n>Prompt:</span>
                                    <span>{{ mediaAIPrompterText.value }}</span>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </div>
                    <div class="editor-wrapper" fxLayout="column" fxLayoutAlign="start start">
                        <div
                            *ngIf="getFieldLabel('description')"
                            class="third-colmn-editor-wrapper mb-1"
                            fxFlex="0 1 100%"
                            fxLayout="column"
                        >
                            <mat-expansion-panel [expanded]="true" class="user-manage-course-expantion res-creat-manag">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <mat-icon>description</mat-icon>
                                        <div>{{ getFieldLabel('description', true) }}</div>
                                        <mat-icon
                                            class="icon-explain"
                                            [matTooltip]="getFieldLabel('description', false)"
                                            >help_outline</mat-icon
                                        >
                                    </mat-panel-title>
                                    <mat-panel-description fxLayoutAlign="end center">
                                        <span
                                            matTooltip="Indicator of field change"
                                            i18n-matTooltip
                                            class="cell-indicator"
                                            [ngClass]="{ green: resourceForm.get('description').dirty }"
                                        ></span>
                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <ng-template matExpansionPanelContent>
                                    <mat-form-field class="summary-field" fxFlex="0 1 100%">
                                        <textarea
                                            #description
                                            matInput
                                            placeholder="Enter text"
                                            i18n-placeholder
                                            minRows="3"
                                            cdkAutosizeMinRows="1"
                                            cdkTextareaAutosize
                                            #autosize="cdkTextareaAutosize"
                                            cdkAutosizeMaxRows="150"
                                            formControlName="description"
                                            [maxLength]="defaultResourceGoalCount"
                                        ></textarea>
                                        <button
                                            *ngIf="description.value.length"
                                            mat-icon-button
                                            class="clear-btn"
                                            matSuffix
                                            type="button"
                                            (click)="
                                                $event.stopPropagation(); clearField('description'); description.focus()
                                            "
                                        >
                                            <mat-icon>close</mat-icon>
                                        </button>
                                        <div class="bullets-info-wrapper">
                                            <span *ngIf="description.value" class="char-count">
                                                {{ description.value.length }}
                                                <span>(</span>
                                                {{ defaultResourceGoalCount - description.value.length }}
                                                <span i18n="@@left">left</span>
                                                <span>)</span>
                                            </span>
                                        </div>
                                    </mat-form-field>
                                </ng-template>
                            </mat-expansion-panel>
                        </div>
                        <div
                            *ngIf="getFieldLabel('instructions')"
                            class="third-colmn-editor-wrapper mb-1"
                            fxFlex="0 1 100%"
                            fxLayout="column"
                        >
                            <mat-expansion-panel [expanded]="true" class="user-manage-course-expantion res-creat-manag">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <mat-icon>subject</mat-icon>
                                        <div>{{ getFieldLabel('instructions', true) }}</div>
                                        <mat-icon
                                            class="icon-explain"
                                            [matTooltip]="getFieldLabel('instructions', false)"
                                            >help_outline</mat-icon
                                        >
                                    </mat-panel-title>
                                    <mat-panel-description fxLayoutAlign="end center">
                                        <span
                                            matTooltip="Indicator of field change"
                                            i18n-matTooltip
                                            class="cell-indicator"
                                            [ngClass]="{ green: resourceForm.get('instructions').dirty }"
                                        ></span>
                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <ng-template matExpansionPanelContent>
                                    <mat-form-field class="summary-field" fxFlex="0 1 100%">
                                        <textarea
                                            #instructions
                                            matInput
                                            placeholder="Enter text"
                                            i18n-placeholder
                                            minRows="3"
                                            formControlName="instructions"
                                            cdkTextareaAutosize
                                            #autosize="cdkTextareaAutosize"
                                            cdkAutosizeMinRows="1"
                                            cdkAutosizeMaxRows="10"
                                            [maxLength]="defaultResourceGoalCount"
                                        ></textarea>
                                        <button
                                            *ngIf="instructions.value.length"
                                            mat-icon-button
                                            class="clear-btn"
                                            matSuffix
                                            type="button"
                                            (click)="
                                                $event.stopPropagation();
                                                clearField('instructions');
                                                instructions.focus()
                                            "
                                        >
                                            <mat-icon>close</mat-icon>
                                        </button>
                                        <div class="bullets-info-wrapper">
                                            <span *ngIf="instructions.value" class="char-count">
                                                {{ instructions.value.length }}
                                                <span>(</span>
                                                {{ defaultResourceGoalCount - instructions.value.length }}
                                                <span i18n="@@left">left</span>
                                                <span>)</span>
                                            </span>
                                        </div>
                                    </mat-form-field>
                                </ng-template>
                            </mat-expansion-panel>
                        </div>
                    </div>
                    <ng-container [ngTemplateOutlet]="resourceComposer?.contentTemplateToBeInParent"></ng-container>
                </mat-tab>
            </mat-tab-group>
        </div>
        <div
            fxFlex="0 0 28%"
            fxFlex.lt-md="100%"
            class="main-row"
            fxLayoutGap="15px"
            fxLayout="column"
            fxLayoutAlign="start start"
        >
            <app-resource-manager-controls
                #resToolsComp
                [typeId]="typeId"
                [resource]="resource"
                [resKeysValidaty]="resKeysValidaty"
                [resourceBuilder]="resourceComposer?.resourceBuilder"
                [loading]="loading"
                [user]="user"
                [customer]="customer$ | async"
            ></app-resource-manager-controls>
        </div>
    </div>
</form>
<ng-template #coverImg>
    <app-image-view
        *ngIf="resourceForm.get('media_id')?.value"
        class="mt-1 mb-1"
        [media_id]="resourceForm.get('media_id').value"
        (click)="showImgModal(resourceForm.get('media_id')?.value)"
        [proportional]="false"
    >
        <button
            class="remove-image"
            mat-icon-button
            color="warn"
            type="button"
            *ngIf="resourceForm.get('media_id').value as mediaId"
            matTooltip="Remove image"
            i18n-matTooltip
            (click)="resourceForm.get('media_id').setValue(null); resourceForm.get('media_id').markAsDirty()"
        >
            <mat-icon class="md-24">delete_outline</mat-icon>
        </button>
    </app-image-view>
</ng-template>

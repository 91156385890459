<div class="{{ selectingStyleClass }}" class="res-listing-wrapper user-gen-listing" fxLayout="column" fxFlex="0 0 100%">
    <app-table-toolbar class="toolbar" color="accent">
        <mat-accordion *ngIf="areFiltersInAccordionInDesktop" fxFlex="0 0 100%" displayMode="flat">
            <mat-expansion-panel [expanded]="filtersAreExpanded" #expansionPanel>
                <mat-expansion-panel-header> </mat-expansion-panel-header>
                <ng-container [ngTemplateOutlet]="filtersParent"></ng-container>
            </mat-expansion-panel>
        </mat-accordion>

        <ng-container *ngIf="!areFiltersInAccordionInDesktop" [ngTemplateOutlet]="filtersParent"></ng-container>

        <mat-progress-bar
            class="progress progress-table-loader"
            *ngIf="(dataSource.status | async) === 'loading'"
            mode="indeterminate"
        >
        </mat-progress-bar>
    </app-table-toolbar>

    <div *ngIf="showActionHeader && !canRestoreUser" class="header-replace">
        <div class="hover-wrapper top" fxLayoutAlign="start center">
            <ng-container [ngTemplateOutlet]="selectiveTableActionBtns"></ng-container>
        </div>
    </div>
    <div class="table-wrapper">
        <mat-table
            #table
            [dataSource]="dataSource"
            [matSortActive]="activeSortingCol"
            [matSortDirection]="activeSortingDirection"
            matSort
            [class.mobileView]="isMobile"
            [class.showHeader]="showActionHeader"
        >
            <ng-container matColumnDef="select">
                <mat-header-cell *matHeaderCellDef>
                    <!-- <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        [aria-label]="checkboxLabel()"
                    >
                    </mat-checkbox> -->
                </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(user) : null"
                        [checked]="selection.isSelected(user)"
                        [aria-label]="checkboxLabel(user)"
                    >
                    </mat-checkbox>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@name"> Name </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <span [class.original]="allowActionRow">
                        <!-- <div class="user-img">{{ user.name | nameSign }}</div> -->
                        <app-image-view
                            [ngStyle]="{ minWidth: '50px' }"
                            [isCircularImg]="true"
                            [ignoreThumbnail]="true"
                            [media_id]="user.media"
                            paddingBottom="50px"
                        ></app-image-view>
                        <span *ngIf="!isMobile">{{ user.name }}</span>
                        <div *ngIf="isMobile" fxLayout="column">
                            <span>{{ user.name }}</span>
                            <span>{{ user.email }}</span>
                        </div>
                    </span>
                    <div *ngIf="isMobile" [ngStyle]="{ color: user.roleValueSpecs.color }">
                        <mat-icon class="mr-8" *ngIf="isMobile">{{ user.roleValueSpecs.icon }}</mat-icon>
                    </div>
                    <button
                        *ngIf="isMobile"
                        class="expand-btn"
                        mat-icon-button
                        (click)="expandedRow = expandedRow === user ? null : user"
                    >
                        <mat-icon>
                            {{ expandedRow === user ? 'expand_less' : 'expand_more' }}
                        </mat-icon>
                    </button>
                    <div *ngIf="!isMobile && !canRestoreUser" [class.hover]="allowActionRow">
                        <div class="hover-wrapper" fxLayoutAlign="space-between center">
                            <ng-container
                                *ngIf="!canRestoreUser"
                                [ngTemplateOutlet]="tableCellActionBtns"
                                [ngTemplateOutletContext]="{ user: user }"
                            ></ng-container>
                            <ng-container
                                *ngIf="canRestoreUser"
                                [ngTemplateOutlet]="tableCellActionBtnRestore"
                                [ngTemplateOutletContext]="{ user: user }"
                            ></ng-container>
                        </div>
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="language">
                <mat-header-cell
                    (mouseover)="showLangFilter = true"
                    (mouseleave)="showLangFilter = false"
                    *matHeaderCellDef
                >
                    <div fxLayoutAlign="start center">
                        <span i18n="@@lang">Lang</span>
                    </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@lang">Lang</span>
                    <ng-container *ngIf="user.language_id">
                        <app-language-selector
                            [language_id]="user.language_id"
                            class="icon-resource-builder"
                            fxFlex="0 0 auto"
                        ></app-language-selector>
                    </ng-container>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@name"> Name </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <!-- <div class="user-img">{{ user.name | nameSign }}</div> -->
                    <app-image-view
                        [ngStyle]="{ minWidth: '50px' }"
                        [isCircularImg]="true"
                        [ignoreThumbnail]="true"
                        [media_id]="user.media"
                        paddingBottom="50px"
                    ></app-image-view>
                    {{ user.name }}
                    <button
                        *ngIf="isMobile"
                        class="expand-btn"
                        mat-icon-button
                        (click)="expandedRow = expandedRow === user ? null : user"
                    >
                        <mat-icon>
                            {{ expandedRow === user ? 'expand_less' : 'expand_more' }}
                        </mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="occasions_count">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    <span i18n="@@taken">Taken</span>&nbsp;{{ AppScope?.label(type_scope_id, true) }}
                </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <span *ngIf="isMobile" class="mobile-label"
                        ><span i18n="@@taken">Taken</span>&nbsp;{{ AppScope?.label(type_scope_id, true) }}</span
                    >{{ user.occasions_count }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="created_at">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@createdAt"> Creation date </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@createdAt"> Creation date </span>
                    {{ user.created_at | kdDateFormat : 'YYYY-MM-DD' }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@email"> Email </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@email"> Email </span>
                    {{ user.email }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="phone">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@phone"> Phone </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@phone"> Phone </span>
                    {{ user.phone }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="customer">
                <mat-header-cell *matHeaderCellDef i18n="@@customer"> Customer </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@customer"> Customer </span>
                    <div
                        class="customer-wrap-chips"
                        (click)="$event.stopPropagation(); onCustomerClick.next(user.customer.id)"
                    >
                        {{ user.customer?.name }}
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="role_value">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@role"> Role </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@role"> Role </span>
                    <div fxLayoutAlign="start center" [ngStyle]="{ color: user.roleValueSpecs.color }">
                        <mat-icon class="mr-8" *ngIf="!isMobile">{{ user.roleValueSpecs.icon }}</mat-icon>
                        {{ user.roleValueSpecs.title }}
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="expiration">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@expire"> Expire </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@expire"> Expire</span>

                    <span *ngIf="user.end_date">{{ user.end_date | kdDateFormat : 'YYYY-MM-DD' }}</span>
                    <span *ngIf="!user.end_date" i18n="@@none">None</span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="last_login">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@lastLogin"> Last login </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@lastLogin"> Last login</span>

                    <span *ngIf="user.last_login">{{ user.last_login | kdDateFormat : 'YYYY-MM-DD' }}</span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="login_status">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@online"> Online </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@online"> Online</span>

                    <span *ngIf="user.login_status">{{ user.login_status }}</span>
                    <span [matTooltip]="getTooltipLoginStatus(user.login_status)" *ngIf="!user.login_status"
                        ><div class="login-status offline-login-state"></div
                    ></span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="klms_time_spent">
                <mat-header-cell *matHeaderCellDef>
                    {{ klmsSpentTimeColTitle }}
                </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <span *ngIf="isMobile" class="mobile-label"> {{ klmsSpentTimeColTitle }}</span>
                    <span *ngIf="user.klms_time_spent">{{ user.klms_time_spent | formatIsoDuration }}</span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="courses_time_spent">
                <mat-header-cell *matHeaderCellDef>
                    {{ coursesSpentTimeColTitle }}
                </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <span *ngIf="isMobile" class="mobile-label">{{ coursesSpentTimeColTitle }}</span>
                    <span *ngIf="user.courses_time_spent">{{ user.courses_time_spent | formatIsoDuration }}</span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="validity">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@validity"> Validity </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@validity"> Validity</span>

                    <mat-icon [ngClass]="checkValidaty(user.end_date) === 'event_busy' ? 'redColor' : 'normalColor'">{{
                        checkValidaty(user.end_date)
                    }}</mat-icon>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="percentage">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@correct"> Correct </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@correct"> Correct</span
                    >{{ user.finalResult?.percentage }}%
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="correct">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@qSCorrect"> QS Correct </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@qSCorrect"> QS Correct</span>
                    {{ user.finalResult?.correct }}/{{ user.finalResult?.questions }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="grade">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@grade"> Grade </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@grade"> Grade</span>
                    {{ user.finalResult?.grade }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="passed">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@pass"> Pass </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@pass">Pass</span>
                    <span i18n="@@yes" *ngIf="user.finalResult?.passed"> Yes </span>
                    <span i18n="@@no" *ngIf="!user.finalResult?.passed"> No </span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="started_at">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ AppScope?.label(type_scope_id, false) }}&nbsp;<span i18n="@@date">Date</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <span *ngIf="isMobile" class="mobile-label"
                        >{{ AppScope?.label(type_scope_id, false) }}&nbsp;<span i18n="@@date">Date</span></span
                    >
                    {{ user.finalResult?.started_at | kdDateFormat : 'YYYY-MM-DD' }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="completed_at">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ AppScope?.label(type_scope_id, false) }}&nbsp;<span i18n="@@date">Date</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <span *ngIf="isMobile" class="mobile-label"
                        >{{ AppScope?.label(type_scope_id, false) }}&nbsp;<span i18n="@@date">Date</span></span
                    >
                    {{ user.finalResult?.done_at | kdDateFormat : 'YYYY-MM-DD' }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="occasionStatus">
                <mat-header-cell mat-sort-header *matHeaderCellDef>
                    <div fxLayoutAlign="start center">
                        <span i18n="@@status">Status</span>
                    </div>
                </mat-header-cell>
                <mat-cell
                    *matCellDef="let resource"
                    class="pr-0-m"
                    [ngStyle]="{ color: (resource | resourceStatus : true)?.color }"
                >
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@status">Status</span>
                    <mat-icon>
                        {{ (resource | resourceStatus : true)?.icon }}
                    </mat-icon>
                    &nbsp;
                    {{ (resource | resourceStatus : true)?.status }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="opportunity_end_date">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@deadline"> Deadline </mat-header-cell>
                <mat-cell [class.no-display-cell]="!resource.opportunity_end_date" *matCellDef="let resource">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@deadline">Deadline</span>
                    <span>
                        {{ resource.opportunity_end_date | kdDateFormat : 'YYYY-MM-DD' }}
                    </span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="opportunity_assign_date">
                <mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@assignedDate">
                    Assigned date
                </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@assignedDate">Assigned date</span>
                    <span>
                        {{ resource.opportunity_assign_date | kdDateFormat : 'YYYY-MM-DD' }}
                    </span>
                    <!-- <span *ngIf="!resource.done" i18n="@@notYet">Not yet</span> -->
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="done_items">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@activityProgress">
                    Progress
                </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@activityProgress">Progress</span>
                    <span>
                        <div class="progress-wrapper">
                            <span class="progress" [ngStyle]="{ width: '50%' }"></span>
                            <span class="remain">{{ resource.done_items ? resource.done_items : '0' }}</span>
                            <span class="total">{{ attachedResource.number_of_items }}</span>
                        </div>
                    </span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="last_action">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@lastAction"> Last action </mat-header-cell>
                <mat-cell [class.no-display-cell]="!resource.last_action" *matCellDef="let resource">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@lastAction">Last action</span>
                    <span>
                        {{ resource.last_action | kdDateFormat : 'YYYY-MM-DD HH:mm' }}
                    </span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="done_at">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    <span i18n="@@completionDate">Completion date</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@completionDate">Completion date</span>
                    {{ user.finalResult?.done_at | kdDateFormat : 'YYYY-MM-DD' }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="duration">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ AppScope?.label(type_scope_id, false) }}&nbsp;<span i18n="@@time"> Time</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@time"> Time</span>
                    {{ user.finalResult?.duration | formatIsoDuration }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="tools">
                <mat-header-cell *matHeaderCellDef i18n="@@tools"> Tools </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <ng-container
                        *ngIf="!canRestoreUser"
                        [ngTemplateOutlet]="tableCellActionBtns"
                        [ngTemplateOutletContext]="{ user: user }"
                    ></ng-container>
                    <ng-container
                        *ngIf="canRestoreUser"
                        [ngTemplateOutlet]="tableCellActionBtnRestore"
                        [ngTemplateOutletContext]="{ user: user }"
                    ></ng-container>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="quick_tools">
                <mat-header-cell *matHeaderCellDef i18n="@@tools"> Tools </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <button
                        *ngIf="!canRestoreUser"
                        [matTooltip]="authUser?.getActivationMailInfo(user)?.tooltip"
                        mat-icon-button
                        [ngClass]="authUser?.getActivationMailInfo(user)?.class"
                        (click)="$event.stopPropagation(); user.customer_published && onSendEmailToUser(user)"
                    >
                        <mat-icon>email</mat-icon>
                    </button>
                    <button
                        *ngIf="!canRestoreUser"
                        matTooltip="Send message"
                        i18n-matTooltip
                        mat-icon-button
                        (click)="$event.stopPropagation(); sendMsg(user)"
                    >
                        <mat-icon>speaker_notes</mat-icon>
                    </button>
                    <button
                        *ngIf="!canRestoreUser"
                        matTooltip="Edit"
                        i18n-matTooltip="@@edit"
                        mat-icon-button
                        (click)="$event.stopPropagation(); authUser.canEdit(user) && onUserEdit.emit(user)"
                        [class.disableIt]="!authUser.canEdit(user)"
                    >
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button
                        *ngIf="canRestoreUser"
                        mat-icon-button
                        matTooltip="Restore"
                        (click)="$event.stopPropagation(); authUser.canDelete(user) && restoreUser(user)"
                        [class.disableIt]="!authUser.canDelete(user)"
                        i18n-matTooltip="@@restore"
                    >
                        <mat-icon>restore_from_trash</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
            <mat-row
                (click)="
                    $event.stopPropagation(); !user.disabled && !isMobile && !canRestoreUser && onRowClicked.emit(user)
                "
                *matRowDef="let user; columns: columns"
                [class.res-expanded-row]="expandedRow === user"
                [class.disableRow]="user.disabled"
                [class.disableIt]="user.disabled"
            ></mat-row>
        </mat-table>
    </div>
</div>
<mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>

<ng-template #loadingTemp>
    <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="center" class="spinner-container">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
<ng-template #rowHover>
    <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="center" class="spinner-container">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
<ng-template #shareLoadTemp>
    <app-kd-logo-loading size="small"></app-kd-logo-loading>
</ng-template>
<ng-template #tableCellActionBtns let-user="user">
    <!-- <button
        *ngIf="tableTools.includes(ResourceTableActions.EMAIL)"
        class="tools-btn"
        mat-icon-button
        matTooltip="Email User"
        (click)="$event.stopPropagation(); onSendEmailToUser(user)"
        i18n-matTooltip="@@emailUser"
    >
        <mat-icon>email</mat-icon>
    </button> -->
    <button
        *ngIf="
            tableTools.includes(ResourceTableActions.ACTIVATION_EMAIL) ||
            tableTools.includes(ResourceTableActions.EMAIL)
        "
        [matTooltip]="authUser?.getActivationMailInfo(user)?.tooltip"
        mat-icon-button
        [ngClass]="authUser?.getActivationMailInfo(user)?.class"
        (click)="$event.stopPropagation(); onSendEmailToUser(user)"
    >
        <mat-icon>email</mat-icon>
    </button>
    <button
        *ngIf="tableTools.includes(ResourceTableActions.VIEW)"
        class="tools-btn"
        mat-icon-button
        matTooltip="View"
        [class.disableIt]="!user.name"
        (click)="onBrowse.emit(user)"
        i18n-matTooltip="@@view"
    >
        <mat-icon>visibility</mat-icon>
    </button>
    <button
        *ngIf="tableTools.includes(ResourceTableActions.TEST_RESULTS)"
        [class.disableIt]="!user.test_occasions || user.test_occasions <= 0"
        matTooltip="Show test results"
        mat-icon-button
        i18n-matTooltip="@@showTestResults"
        (click)="$event.stopPropagation(); onShowTestResults.emit(user)"
    >
        <mat-icon class="kd-icon-24" fontSet="klickdata" fontIcon="kd-icon-test"></mat-icon>
    </button>

    <button
        *ngIf="tableTools.includes(ResourceTableActions.RECOMMEND)"
        matTooltip="Recommend"
        mat-icon-button
        (click)="$event.stopPropagation(); authUser.canEdit(user) && onUserRecommend.emit(user)"
        [class.disableIt]="!authUser.canEdit(user)"
        i18n-matTooltip="@@recommend"
    >
        <mat-icon>recommend</mat-icon>
    </button>

    <button
        *ngIf="onUserSpecify.observers.length"
        matTooltip="Specify"
        mat-icon-button
        (click)="$event.stopPropagation(); authUser.canEdit(user) && onUserSpecify.emit(user)"
        [class.disableIt]="!authUser.canEdit(user)"
        i18n-matTooltip="@@specify"
    >
        <mat-icon>checklist</mat-icon>
    </button>
    <button
        *ngIf="onUserAccessControl.observers.length"
        matTooltip="Access control"
        mat-icon-button
        (click)="$event.stopPropagation(); authUser.canEdit(user) && onUserAccessControl.emit(user)"
        [class.disableIt]="!authUser.canEdit(user)"
        i18n-matTooltip="@@AccessControl"
    >
        <mat-icon>manage_accounts</mat-icon>
    </button>

    <button
        *ngIf="onUserCollect.observers.length"
        class="tools-btn"
        matTooltip="Sections"
        mat-icon-button
        [class.disableIt]="!authUser.canEdit(user)"
        (click)="$event.stopPropagation(); authUser.canEdit(user) && onUserCollect.next(user)"
        i18n-matTooltip="@@sections"
    >
        <mat-icon>widgets</mat-icon>
    </button>

    <button
        *ngIf="tableTools.includes(ResourceTableActions.USER_EDIT)"
        [matTooltip]="user.roleValueSpecs.title"
        mat-icon-button
        (click)="$event.stopPropagation(); authUser.canEdit(user) && onUserEdit.emit(user)"
        [class.disableIt]="!authUser.canEdit(user)"
        [ngStyle]="{ color: user.roleValueSpecs.color }"
    >
        <mat-icon>{{ isMobile ? 'edit' : user.roleValueSpecs.icon }}</mat-icon>
    </button>
    <button
        *ngIf="tableTools.includes(ResourceTableActions.NOTES)"
        matTooltip="Add note"
        mat-icon-button
        i18n-matTooltip
        (click)="$event.stopPropagation(); onUserNote.emit(user)"
    >
        <mat-icon>rate_review</mat-icon>
    </button>

    <button
        *ngIf="tableTools.includes(ResourceTableActions.EDIT)"
        class="tools-btn edit-user-btn"
        mat-icon-button
        (click)="$event.stopPropagation(); browse(user)"
        matTooltip="Edit"
        i18n-matTooltip="@@edit"
    >
        <mat-icon class="material-icons-outlined">edit</mat-icon>
    </button>
    <button
        *ngIf="tableTools.includes(ResourceTableActions.DELETE) && !canRestoreUser"
        mat-icon-button
        matTooltip="Delete"
        (click)="$event.stopPropagation(); authUser.canDelete(user) && remove([user])"
        [class.disableIt]="!authUser.canDelete(user)"
        i18n-matTooltip="@@delete"
    >
        <mat-icon>delete</mat-icon>
    </button>
    <!-- <button
        class="hover-tools-btn"
        *ngIf="!(shareLoading | async); else shareLoadTemp"
        mat-icon-button
        matTooltip="Share"
        i18n-matTooltip="@@share"
        (click)="shareService.openShareDialog(user, shareLoading)"
    >
        <mat-icon>share</mat-icon>
    </button> -->
</ng-template>
<ng-template #tableCellActionBtnRestore let-user="user">
    <button
        *ngIf="tableTools.includes(ResourceTableActions.DELETE) && canRestoreUser"
        mat-icon-button
        matTooltip="Restore"
        (click)="$event.stopPropagation(); authUser.canDelete(user) && restoreUser(user)"
        [class.disableIt]="!authUser.canDelete(user)"
        i18n-matTooltip="@@restore"
    >
        <mat-icon>restore_from_trash</mat-icon>
    </button>
</ng-template>
<ng-template #selectiveTableActionBtns>
    <div fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="space-around center">
        <button
            class="tools-btn"
            mat-button
            matTooltip="Email selected users"
            (click)="$event.stopPropagation(); onSendEmails.emit(selection.selected)"
            i18n-matTooltip="@@emailUser"
        >
            <mat-icon>email</mat-icon>
            <span>Email users</span>
        </button>
        <button
            *ngIf="canCreateGroup"
            class="tools-btn"
            mat-button
            matTooltip="Create group from selection"
            (click)="$event.stopPropagation(); createGroup(selection.selected)"
            i18n-matTooltip
        >
            <mat-icon>groups</mat-icon>
            <span>Create group</span>
        </button>
        <button
            class="tools-btn"
            mat-button
            matTooltip="Export selected users"
            (click)="$event.stopPropagation(); download(selection.selected)"
            i18n-matTooltip="@@exportUsers"
        >
            <mat-icon>save_alt</mat-icon>
            <span>Export users</span>
        </button>
        <!-- <button
            matTooltip="Assign"
            mat-button
            (click)="
                $event.stopPropagation();
                authUser.canEditMultiple(selection.selected) && onUserAssign.emit(selection.selected)
            "
            [class.disableIt]="!authUser.canEditMultiple(selection.selected)"
            i18n-matTooltip="@@assign"
        >
            <mat-icon>assignment</mat-icon>
            <span>Assign users</span>
        </button> -->
        <!-- <button
            *ngIf="!canRestoreUser"
            mat-button
            matTooltip="Delete"
            (click)="
                $event.stopPropagation(); authUser.canDeleteMultiple(selection.selected) && remove(selection.selected)
            "
            i18n-matTooltip="@@delete"
            [class.disableIt]="!authUser.canDeleteMultiple(selection.selected)"
        >
            <mat-icon>delete</mat-icon>
            <span>Delete users</span>
        </button> -->
        <!-- <button
            *ngIf="canRestoreUser"
            mat-button
            matTooltip="Restore"
            (click)="
                $event.stopPropagation(); authUser.canDeleteMultiple(selection.selected) && restoreUser(selection.selected)
            "
            i18n-matTooltip="@@restore"
            [class.disableIt]="!authUser.canDeleteMultiple(selection.selected)"
        >
            <mat-icon>restore_from_trash</mat-icon>
            <span i18n>Restore users</span>
        </button> -->
    </div>
</ng-template>
<ng-template #filtersParent>
    <app-table-filter
        [cacheScope]="AppScope.USERS"
        [toggleFilterButtons]="toggleFilterButtons"
        [filtersTogglesInOnBtn]="true"
        [inGlobalFilter]="true"
        color="accent"
        class="mt-1"
        #filter
    >
        <app-table-search [inGlobalFilter]="true" class="align-space" searchFilterRef></app-table-search>
        <app-table-date-filter [inGlobalFilter]="true" color="accent" dateFilterRef></app-table-date-filter>
        <app-custom-fields-filter
            [inGlobalFilter]="true"
            label="Field"
            i18n-label="@@field"
            customFieldNameRef
        ></app-custom-fields-filter>
        <app-language-filter
            [inGlobalFilter]="true"
            #langFilter
            fxFlex="0 0 auto"
            fxLayout="row"
            langFilterRef
        ></app-language-filter>

        <app-select-filter
            [inGlobalFilter]="true"
            class="filter-space"
            label="Academy Roles"
            i18n-label="@@academyRoles"
            [property]="GlobalFilterProperty.USER_ROLE"
            [options]="userRoleOptions"
            userRolesRef
        >
        </app-select-filter>

        <app-select-filter
            [inGlobalFilter]="true"
            class="filter-space"
            label="Status"
            i18n-label="@@status"
            [property]="{ name: GlobalFilterProperty.STATUS, type: GlobalFilterPropertyType.STATUS_USER }"
            [options]="userStatusOptions"
            userStatusRef
        >
        </app-select-filter>
        <!-- <app-table-date-filter [inGlobalFilter]="true" color="accent" customTimeSpentFilterRef></app-table-date-filter> -->

        <app-group-chip-search-filter [inGlobalFilter]="true" groupSearchRef></app-group-chip-search-filter>
    </app-table-filter>
</ng-template>

<div class="main-wrap" fxLayout="column">
    <div *ngIf="displayedScope" class="w-100">
        <div color="primary" class="question-title-index">
            {{ selection?.length + 1 }}
        </div>
        <ng-container [ngSwitch]="displayedScope">
            <ng-container *ngSwitchCase="AppScope.MATERIAL">
                <app-course-manager-core-add-material
                    #compRef
                    [display]="displayTab"
                    [selection]="selection"
                    (select)="addResource($event); displayedScope = null"
                    (onError)="onError.emit($event)"
                    (onCloseView)="displayedScope = null"
                    (onMultiSelect)="addmultipleResources($event); displayedScope = null"
                ></app-course-manager-core-add-material
            ></ng-container>
            <ng-container *ngSwitchCase="AppScope.TEST">
                <app-course-manager-core-add-test
                    #compRef
                    [display]="displayTab"
                    [selection]="selection"
                    (add)="addResource($event); displayedScope = null"
                    (onMultiSelect)="addmultipleResources($event); displayedScope = null"
                    (onError)="onError.emit($event)"
                    (onCloseView)="displayedScope = null"
                >
                </app-course-manager-core-add-test
            ></ng-container>
            <ng-container *ngSwitchCase="AppScope.SURVEY">
                <app-course-manager-core-add-survey
                    #compRef
                    [display]="displayTab"
                    [selection]="selection"
                    (add)="addResource($event); displayedScope = null"
                    (onMultiSelect)="addmultipleResources($event); displayedScope = null"
                    (onError)="onError.emit($event)"
                    (onCloseView)="displayedScope = null"
                ></app-course-manager-core-add-survey
            ></ng-container>
            <ng-container *ngSwitchCase="AppScope.EVENT">
                <app-course-manager-core-add-event
                    #compRef
                    [display]="displayTab"
                    [selection]="selection"
                    (add)="addResource($event); displayedScope = null"
                    (onMultiSelect)="addmultipleResources($event); displayedScope = null"
                    (onError)="onError.emit($event)"
                    (onCloseView)="displayedScope = null"
                ></app-course-manager-core-add-event
            ></ng-container>
        </ng-container>
    </div>
    <div fxLayout="row wrap" fxLayoutGap="10px" class="w-100 adding-btns">
        <button type="button" mat-button (click)="addCourseItem()">
            <mat-icon>add_circle_outline</mat-icon>
            <span i18n="@@addItem">Add Item</span>
        </button>
    </div>
</div>

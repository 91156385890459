import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { ControlContainer, FormArray, FormBuilder, FormGroup, FormGroupDirective } from '@angular/forms';
import { AuthService } from '@klickdata/core/auth';
import { FormHelper } from '@klickdata/core/form';
import { MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { Alternative, AlternativeData, AlternativeService, Question, QuestionData } from '@klickdata/core/question';
import {
    AppScope,
    Resource,
    ResourceCategoryService,
    ResourceData,
    ResourceService,
    ResourceSocketService,
    ResourceTypeService,
    ResourceTypes,
} from '@klickdata/core/resource';
import { ResourceItem, ResourceItemData, ResourceItemService } from '@klickdata/core/resource-item';
import { EMPTY, Observable, Subject, combineLatest, of } from 'rxjs';
import { filter, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ResourceBuilderService, ResourceBuilderSubmitEvent } from './resource-builder.service';
import { Utils } from '@klickdata/core/util';

@Component({
    selector: 'app-resource-builder-form',
    templateUrl: './resource-builder-form.component.html',
    styleUrls: ['./resource-builder-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class ResourceBuilderFormComponent implements OnInit, OnChanges, OnDestroy {
    @Input() form: FormGroup;
    @Input() resourceForm: FormGroup;
    @Input() usePoints = true;

    public items: Observable<ResourceItem[]>;
    public reorder = false;
    public resource: Resource;
    private destroy: Subject<boolean> = new Subject<boolean>();

    @Output() saving: EventEmitter<boolean> = new EventEmitter();
    @Output() saved: EventEmitter<boolean> = new EventEmitter();
    @Output() removal: EventEmitter<number[]> = new EventEmitter<number[]>();
    public formats: { [key: string]: string };

    constructor(
        public resourceBuilderService: ResourceBuilderService,
        protected resourceService: ResourceService,
        protected resourceSocketService: ResourceSocketService,
        protected typeService: ResourceTypeService,
        protected itemService: ResourceItemService,
        protected alternativeService: AlternativeService,
        protected auth: AuthService,
        protected fb: FormBuilder,
        protected message: MessageService,
        protected categoryService: ResourceCategoryService,
        protected parentFormDirective: FormGroupDirective
    ) {
        this.formats = {
            title: $localize`:@@title:Title`,
            name: $localize`:@@overview:Overview`,
            description: $localize`:@@description:Description`,
            instructions: $localize`:@@instructions:Instructions`,
            grade_system_id: $localize`:@@gradeSystem:Grade system`,
            question: $localize`:@@questions:Questions`,
            child_resource_id: $localize`Select resource`,
            child_resource_language_id: $localize`:@@language:Language`,
        };
    }

    public ngOnInit() {
        /**
         * Items observable
         */
        this.items = this.resourceBuilderService.getItems();

        /**
         * Update resource object
         */
        this.resourceBuilderService
            .getResource()
            .pipe(takeUntil(this.destroy))
            .subscribe((resource) => {
                if (!this.resource && resource) {
                    this.buildResourceSocket(resource);
                }
                this.resource = resource;
                if (resource?.isProcessing()) {
                    this.setLoading(true);
                }
            });

        /**
         * Handle submission and validation
         */
        combineLatest([this.resourceBuilderService.onSubmit(), this.auth.getUser().pipe(first())])
            .pipe(
                filter(([_, user]) => !this.resource || user.canEdit(this.resource)),
                takeUntil(this.destroy),
                switchMap(([options, _]) =>
                    this.resource ? of(options) : this.getResourceObservable().pipe(map(() => options))
                ),
                filter((options) => options.emitSaved || this.form.get('items').valid),
                switchMap((options) =>
                    combineLatest([
                        of(options),
                        this.onSubmit(options),
                        // this.getResourceTypeObservable().pipe(
                        //     switchMap((questionType) => this.onSubmit(options, questionType))
                        // ),
                    ])
                )
            )
            .subscribe(([options, resource]) => {
                if (options.prompt_status !== 'PROCESSING') {
                    this.setLoading(false);
                }

                FormHelper.resetForm(this.form);
                // this.resourceBuilderService.updateResource(resource);
                if (options.emitSaved) {
                    this.saved.emit(true);
                }
            });
    }

    private buildResourceSocket(resource: Resource) {
        this.resourceSocketService
            .listenToResourceUpdates({ res_id: resource.id })
            .pipe(takeUntil(this.destroy))
            .subscribe((resource) => {
                this.setLoading(false);
                this.resourceBuilderService.setResourceItems(resource);
            });
    }

    private getResourceTypeObservable() {
        return this.typeService.getResourceTypeInScope(
            this.resourceBuilderService.resource_type_id,
            AppScope.TEST,
            AppScope.SURVEY
        );
    }

    private getResourceObservable(): Observable<Resource> {
        const data = new Resource({
            type_id: this.resourceBuilderService.resource_type_id,
            ...Utils.dirtyControls(this.parentFormDirective.form),
        }).getPayload();

        return this.resourceService
            .createResourceTemplate(data)
            .pipe(tap((resource) => this.resourceBuilderService.updateResource(resource)));
    }

    public get isResourceTypeWithQuestions(): boolean {
        return (
            this.resourceBuilderService?.resource_type_id == ResourceTypes.GeneralSurvey ||
            this.resourceBuilderService?.resource_type_id == ResourceTypes.GeneralTest
        );
    }

    public ngOnChanges() {}
    public ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    public onSubmit(options: ResourceBuilderSubmitEvent): Observable<any> {
        const items = this.form.get('items') as FormArray;
        if (this.form.invalid) {
            const invalid = [];
            let index = 1;
            for (const item of <FormGroup[]>items.controls) {
                if (!item.valid && !item.value.delete) {
                    invalid.push(`${index}.${FormHelper.formatInvalidForm(item, this.formats)} is required!`);
                }

                const alts = <FormArray>item.get('question.alternatives');
                if (alts?.errors?.missCorrect) {
                    invalid.push(`${index}.${$localize`Correct alternative`} is required!`);
                }
                if (alts?.errors?.minLength) {
                    invalid.push(`${index}.${$localize`Question must contains at least 2 alternative`}`);
                }
                index++;
            }

            if (invalid.length) {
                FormHelper.markForm(items);
                this.message.openMessage(MessageErrorComponent, invalid.join(', '));
                return EMPTY;
            }
        }

        this.setLoading(true);
        return this.submitItems(
            items,
            options,
            ResourceTypes.isResourceWithQuestions(this.resourceBuilderService.resource_type_id)
        );
    }

    /**
     * Set loading status
     * @param status loading status
     */
    private setLoading(status: boolean) {
        this.saving.emit(status);
        this.resourceBuilderService.setLoading(status);
    }

    protected submitItems(
        items: FormArray,
        options: ResourceBuilderSubmitEvent,
        isQuestion: boolean
    ): Observable<Resource> {
        // child resources for update.
        const childResourceUpdateObs: Observable<Resource>[] = [];
        const itemsForSubmit: ResourceItemData[] = [];
        // Create new or deletes items
        for (const item of items.controls) {
            //
            if (options.emitSaved && !isQuestion) {
                const childResourceData = this.getChildResourceData(<FormGroup>item);
                if (childResourceData) {
                    childResourceUpdateObs.push(this.resourceService.update(childResourceData));
                }
            }
            /**
             * Don't save disabled item (with no ids) when click save,
             * Only save with auto save debounce & keep it disabled on UI.
             * Don't use formArray.controls[i].splice,
             * that will remove the control from the array,
             * but the control values will remain on the values array of the FormArray control
             *
             * items.removeAt(items.controls.indexOf(item));
             */
            if (options.emitSaved && item.disabled && !item.get('id').value) {
                continue;
            }

            const data = this.prepareItem(<FormGroup>item, isQuestion, options);
            if (options.emitSaved && item.disabled && item.get('id').value) {
                data.deleted = true;
            }
            itemsForSubmit.push(data);
        }

        const payload = { ...options.extraPayload, resource_items: itemsForSubmit, resource_id: this.resource.id };
        return this.itemService.sync(payload).pipe(
            map((results) => {
                /**
                 * Remove deleted controls from FormArray after sync with server By user save action,
                 * Auto save will keep disabled until user save action.
                 */
                if (options.emitSaved) {
                    this.removal.emit(
                        items.controls.filter((item) => item.disabled).map((item) => item.value.child_resource_id)
                    );
                    this.removeFormArrayDeletedItems(items);
                }
                return results.map((rItem, index) => {
                    const item = items.at(index);
                    if (!item) {
                        return rItem;
                    }

                    // Patch item id into resource item form
                    item.patchValue({
                        id: rItem.id,
                    });

                    const questionForm = <FormGroup>item.get('question');
                    if (isQuestion && questionForm) {
                        // patch question id/item_id into question form group
                        questionForm.patchValue({
                            id: rItem.question_id,
                            item_id: rItem.id,
                        });

                        const altFormArray = <FormArray>item.get('question.alternatives');
                        // Remove deleted alts from FormArray after sync with server
                        if (altFormArray) {
                            if (options.emitSaved) {
                                this.removeFormArrayDeletedItems(altFormArray);
                            }

                            rItem.alternative_ids?.forEach((id, i) => {
                                const altForm = altFormArray.at(i);
                                // patch alt id after saved on server.
                                altForm?.patchValue({ id: id });
                            });
                        }
                    }
                    return rItem;
                });
            }),
            switchMap((data: ResourceItem[]) =>
                this.submitResource(data.map((item) => item.id)).pipe(
                    map((resource) => {
                        this.resource.start_item = resource.start_item;
                        this.resource.last_item = resource.last_item;
                        this.resource.item_ids = resource.item_ids;
                        return resource;
                    })
                )
            ),
            switchMap((resource) =>
                !childResourceUpdateObs.length
                    ? of(resource)
                    : combineLatest([...childResourceUpdateObs]).pipe(map(() => resource))
            )
        );
    }

    /**
     * Remove deleted item away from FormArray.
     * @results items array.
     * @formArray FormArray controls.
     */
    private removeFormArrayDeletedItems(formArray: FormArray) {
        const deletedItems = formArray.controls.filter((item) => item.disabled);
        for (const item of deletedItems) {
            formArray.removeAt(formArray.controls.indexOf(item));
        }
    }

    protected submitResource(item_ids: number[]): Observable<Resource> {
        return this.resourceService.update(
            this.resource.getData({
                item_ids: item_ids,
                start_item: item_ids[0],
                last_item: item_ids[item_ids.length - 1],
            })
        );
    }

    /**
     * Prepare a form item.
     *
     * @param item - Current item
     */
    protected prepareItem(item: FormGroup, isQuestion: boolean, options: ResourceBuilderSubmitEvent): ResourceItemData {
        const itemData: ResourceItemData = new ResourceItem({
            id: item.value.id,
            title: item.value.title,
            name: item.value.name ?? 'Title',
            description: item.value.description,
            instructions: item.value.instructions,
            mandatory: item.value.mandatory,
            percentage: item.value.percentage,
            repeats: item.value.repeats,
            item_type_value: item.value.item_type_value,
            child_resource_id: item.value.child_resource_id,
            child_items: item.value.child_items,
        }).getData();

        const questionForm = <FormGroup>item.get('question');
        const alternativesForm = <FormArray>item.get('question.alternatives');
        if (isQuestion && (questionForm.dirty || alternativesForm?.dirty)) {
            const questionData = this.prepareQuestions(questionForm);
            if (alternativesForm?.dirty) {
                questionData.alternatives = this.buildQuestionAlternatives(alternativesForm, options);
            }
            itemData.question = questionData;
        }
        return itemData;
    }

    protected prepareQuestions(group: FormGroup): QuestionData {
        return new Question({ ...group.value, item_id: group.parent.value.id }).getData();
    }

    private buildQuestionAlternatives(altFormArray: FormArray, options: ResourceBuilderSubmitEvent): AlternativeData[] {
        if (!(altFormArray && altFormArray.length)) {
            return null; // drop null or empty alts
        }
        let index = 0;
        const alts: AlternativeData[] = [];
        for (const alternative of altFormArray.controls) {
            if (
                options.emitSaved &&
                alternative.disabled &&
                (!alternative.get('id').value || alternative.get('id').value < 1)
            ) {
                continue;
            }

            const data = this.prepareAlternative(<FormGroup>alternative, index);
            if (options.emitSaved && alternative.disabled && alternative.get('id').value) {
                data.deleted = true;
            }

            alts.push(data);
            index++;
        }
        return alts;
    }

    protected prepareAlternative(group: FormGroup, weight: number): AlternativeData {
        return new Alternative({ ...group.value, question_id: group.parent.parent.value.id, weight: weight }).getData();
    }

    /**
     * Return if child resource is dirty for updates.
     * Then define property for updates.
     */
    private getChildResourceData(item: FormGroup): ResourceData {
        if (item.get('child_resource_id')?.value && item.get('child_resource_language_id').dirty) {
            return {
                id: item.get('child_resource_id').value,
                language_id: item.get('child_resource_language_id').value,
            };
        }
    }
}

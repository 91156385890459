<div fxLayout="row wrap" class="res-item qs-form-build">
    <mat-accordion
        class="resources-list"
        fxFlex="0 1 100%"
        fxFlex.lt-lg="100%"
        fxFlex.lt-md="100%"
        cdkDropList
        [multi]="true"
        #accordion
        (cdkDropListDropped)="drop($event)"
    >
        <mat-expansion-panel
            *ngFor="let item of itemFormArray.controls; let i = index"
            #panel
            class="item no-shadow"
            [class.disabled]="item.disabled"
            cdkDrag
            cdkDragLockAxis="y"
            [expanded]="expanded === i || allExpanded"
            (opened)="onOpen(i)"
            [disabled]="item.disabled"
        >
            <mat-expansion-panel-header
                [collapsedHeight]="'60px'"
                [expandedHeight]="'60px'"
                class="question-header res-item-header"
                [ngClass]="{ 'header-test-expanded': item.value.item_type_value == 'test' }"
            >
                <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                    <div
                        fxFlex="0 1 auto"
                        class="question-title-index"
                        [ngClass]="{ 'wok-qus': item.get('question')?.value['isWOKQs'] }"
                    >
                        {{ i + 1 }}
                    </div>
                    <div fxFlex="0 1 auto" fxLayout="column" class="item-desc">
                        <div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
                            <ng-container [ngSwitch]="displayCorrectIcon(item.get('item_type_value').value)">
                                <span *ngSwitchCase="'e-course'" fxFlex="0 1 auto" fxLayout="row">
                                    <mat-icon fontSet="klickdata" fontIcon="kd-icon-player"></mat-icon>
                                    <span i18n>E-course</span>
                                </span>
                                <span *ngSwitchCase="'course'" fxFlex="0 1 auto" fxLayout="row">
                                    <mat-icon fontSet="klickdata" fontIcon="kd-icon-task"></mat-icon>
                                    <span i18n>Course</span>
                                </span>
                                <span
                                    *ngSwitchCase="'test'"
                                    fxFlex="0 1 auto"
                                    fxLayout="row"
                                    fxLayoutAlign="start center"
                                >
                                    <mat-icon fontSet="klickdata" fontIcon="kd-icon-test"></mat-icon>
                                    <span i18n>Test</span>
                                </span>
                                <span *ngSwitchCase="'survey'" fxFlex="0 1 auto" fxLayout="row">
                                    <mat-icon fontSet="klickdata" fontIcon="kd-icon-survey"></mat-icon>
                                    <span i18n>Survey</span>
                                </span>
                                <span *ngSwitchCase="'event'" fxFlex="0 1 auto" fxLayout="row">
                                    <mat-icon>event</mat-icon>
                                    <span i18n>Event</span>
                                </span>
                                <span *ngSwitchCase="'doc'" fxFlex="0 1 auto" fxLayout="row">
                                    <mat-icon fontSet="klickdata" fontIcon="kd-icon-document"></mat-icon>
                                    <span i18n>Document material</span>
                                </span>
                                <span *ngSwitchCase="'url'" fxFlex="0 1 auto" fxLayout="row">
                                    <mat-icon fontSet="klickdata" fontIcon="kd-icon-document"></mat-icon>
                                    <span i18n>Link material</span>
                                </span>
                                <span *ngSwitchCase="'text'" fxFlex="0 1 auto" fxLayout="row">
                                    <mat-icon fontSet="klickdata" fontIcon="kd-icon-document"></mat-icon>
                                    <span i18n>Textmaterial</span>
                                </span>
                                <span *ngSwitchCase="'material'" fxFlex="0 1 auto" fxLayout="row">
                                    <mat-icon fontSet="klickdata" fontIcon="kd-icon-document"></mat-icon>
                                    <span i18n>Material</span>
                                </span>
                            </ng-container>
                        </div>

                        <p *ngIf="!panel.expanded" fxFlex="0 1 auto">
                            {{ item.get('title').value ?? item.get('name').value }}
                        </p>
                    </div>
                </mat-panel-title>

                <mat-panel-description class="btns-desc" fxLayout="row" fxLayoutAlign="end center">
                    <ng-container *ngIf="isTest(item.value.item_type_value)">
                        <!-- Passing Level -->
                        <a
                            *ngIf="!!item.value.child_resource_id && panel.expanded"
                            matTooltip="Preview"
                            i18n-matTooltip="@@preview"
                            mat-icon-button
                            [routerLink]="['/player/resource/', item.value.child_resource_id]"
                            target="_blank"
                        >
                            <mat-icon> open_in_new </mat-icon>
                        </a>
                        <button
                            *ngIf="panel.expanded"
                            mat-icon-button
                            color="primary"
                            matTooltip="Exchange"
                            i18n-matTooltip="@@exchange"
                            (click)="$event.stopPropagation(); change()"
                        >
                            <mat-icon>swap_horiz</mat-icon>
                        </button>
                        <button
                            *ngIf="panel.expanded || !!item.value.percentage"
                            [matTooltip]="getPassingLevelLabel(item.value)"
                            i18n-matTooltip
                            (click)="$event.stopPropagation()"
                            mat-icon-button
                            [matMenuTriggerFor]="passingLevelMenu"
                            [ngClass]="{
                                grey: !item.value.percentage || item.value.percentage == 0,
                                green: item.value.percentage && item.value.percentage > 0
                            }"
                        >
                            <mat-icon matSuffix>private_connectivity</mat-icon>
                        </button>

                        <mat-menu
                            #passingLevelMenu="matMenu"
                            class="passing-menu-panel percent"
                            [hasBackdrop]="true"
                            [overlapTrigger]="false"
                        >
                            <div class="tooltip-content" matMenuCloseOnClick="false">
                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                    <span i18n>Passing percentage</span>
                                    <mat-slide-toggle
                                        color="accent"
                                        [checked]="item.value.percentage && item.value.percentage > 0"
                                        (click)="$event.stopPropagation()"
                                        (change)="
                                            $event.checked
                                                ? item.get('percentage').patchValue(75)
                                                : item.get('percentage').patchValue(null)
                                        "
                                    >
                                    </mat-slide-toggle>
                                </div>

                                <mat-form-field
                                    *ngIf="item.value.percentage && item.value.percentage > 0"
                                    class="passing-data"
                                >
                                    <input
                                        matInput
                                        type="number"
                                        [formControl]="item.get('percentage') | cast"
                                        (click)="$event.stopPropagation()"
                                    />
                                    <span matTextSuffix>%</span>
                                </mat-form-field>
                            </div>
                        </mat-menu>

                        <!-- Repeats -->
                        <button
                            *ngIf="panel.expanded || !!item.value.repeats"
                            [matTooltip]="getRepeatsLabel(item.value)"
                            (click)="$event.stopPropagation()"
                            mat-icon-button
                            [matMenuTriggerFor]="repeatsMenu"
                            [ngClass]="{
                                grey: item.value.repeats == null,
                                red: item.value.repeats == 0,
                                green: item.value.repeats && item.value.repeats > 0
                            }"
                        >
                            <mat-icon matSuffix>restart_alt</mat-icon>
                        </button>

                        <mat-menu
                            #repeatsMenu="matMenu"
                            class="passing-menu-panel"
                            [hasBackdrop]="true"
                            [overlapTrigger]="false"
                        >
                            <div class="tooltip-content" matMenuCloseOnClick="false">
                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                    <span i18n>Infinte Repeats</span>
                                    <mat-slide-toggle
                                        color="accent"
                                        [checked]="item.value.repeats == null"
                                        (click)="$event.stopPropagation()"
                                        (change)="
                                            $event.checked
                                                ? item.get('repeats').patchValue(null)
                                                : item.get('repeats').patchValue(0)
                                        "
                                    >
                                    </mat-slide-toggle>
                                </div>
                                <mat-form-field *ngIf="item.value.repeats != null" class="passing-data">
                                    <input
                                        matInput
                                        type="number"
                                        [formControl]="item.get('repeats') | cast"
                                        (click)="$event.stopPropagation()"
                                    />
                                </mat-form-field>
                            </div>
                        </mat-menu>
                    </ng-container>
                    <button
                        mat-icon-button
                        *ngIf="editable(items[i]) && panel.expanded"
                        (click)="toggleDisable($event, item); panel.close()"
                    >
                        <mat-icon *ngIf="!item.disabled" fxFlex="0 1 auto" class="remove-item"> delete </mat-icon>
                        <mat-icon *ngIf="item.disabled" fxFlex="0 1 auto" class="remove-item"> undo </mat-icon>
                    </button>
                    <!-- The ngIf directive for preventing appearance in type question -->
                    <app-language-selector
                        *ngIf="items[i].child_resource_language_id"
                        class="icon-resource-builder"
                        fxFlex="0 1 auto"
                        (click)="$event.stopPropagation()"
                        [formControl]="item.get('child_resource_language_id') | cast"
                    ></app-language-selector>
                    <button
                        *ngIf="!item.disabled && editable(items[i])"
                        mat-icon-button
                        (click)="duplicate($event, item)"
                    >
                        <mat-icon fxFlex="0 1 auto" class="duplicate-item"> content_copy </mat-icon>
                    </button>

                    <mat-icon cdkDragHandle fxFlex="0 1 auto" class="question-reorder"> reorder </mat-icon>

                    <button
                        *ngIf="editable(items[i]) && panel.expanded"
                        mat-icon-button
                        color="primary"
                        matTooltip="Edit"
                        i18n-matTooltip="@@edit"
                        [disabled]="!editable(items[i])"
                        (click)="$event.stopPropagation(); onEdit()"
                    >
                        <mat-icon>edit</mat-icon>
                    </button>

                    <!-- Mandatory -->
                    <button
                        [matTooltip]="getTooltipText(sliderToggle.checked)"
                        i18n-matTooltip
                        (click)="$event.stopPropagation()"
                        mat-icon-button
                        [matMenuTriggerFor]="mandatoryMenu"
                        [ngClass]="{
                            grey: !item.value.mandatory,
                            green: item.value.mandatory
                        }"
                    >
                        <mat-icon>alt_route</mat-icon>
                    </button>

                    <mat-menu
                        #mandatoryMenu="matMenu"
                        class="mandatory-menu-panel percent"
                        [hasBackdrop]="true"
                        [overlapTrigger]="false"
                    >
                        <div class="tooltip-content" matMenuCloseOnClick="false">
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <span i18n>Mandatory</span>
                                <mat-slide-toggle
                                    #sliderToggle
                                    class="ml-1 mandatory-toggle"
                                    color="accent"
                                    [checked]="item.get('mandatory')?.value"
                                    [formControl]="item.get('mandatory') | cast"
                                    (click)="$event.stopPropagation()"
                                >
                                </mat-slide-toggle>
                            </div>
                        </div>
                    </mat-menu>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <app-resource-item-form
                #res_item_ref
                *ngIf="panel.expanded"
                [itemGroup]="item | cast"
                [item]="items[i]"
                [index]="i"
                [usePoints]="usePoints"
            ></app-resource-item-form>
        </mat-expansion-panel>
    </mat-accordion>
</div>

<div class="res-listing-wrapper user-gen-listing msg-lists" fxLayout="column" fxFlex="0 0 100%">
    <app-table-toolbar class="toolbar" color="accent">
        <mat-accordion fxFlex="0 0 100%" displayMode="flat">
            <mat-expansion-panel [expanded]="false" class="msg-listing-panel" #expansionPanel>
                <mat-expansion-panel-header> </mat-expansion-panel-header>
                <app-table-filter [toggleFilterButtons]="true" [inGlobalFilter]="true" color="accent" #filter>
                    <app-select-filter
                        [inGlobalFilter]="true"
                        class="filter-space"
                        label="Type"
                        i18n-label="@@type"
                        [property]="GlobalFilterProperty.MESSAGE_SCOPEID"
                        [options]="messageTypes"
                        messageTypesRef
                    >
                    </app-select-filter>
                    <app-message-name-or-email-chip-search-filter
                        fxFlex="50%"
                        fxFlex.lt-md="100%"
                        messageUserRef
                    ></app-message-name-or-email-chip-search-filter>
                    <app-table-search
                        placeHolder="Filter by subject..."
                        i18n-placeHolder
                        [inGlobalFilter]="true"
                        class="align-space"
                        messageSubjectRef
                    ></app-table-search>
                </app-table-filter>
            </mat-expansion-panel>
        </mat-accordion>

        <mat-progress-bar class="progress progress-table-loader" *ngIf="false" mode="indeterminate"> </mat-progress-bar>
    </app-table-toolbar>
    <div *ngIf="selection.selected.length > 0" class="header-replace">
        <div class="hover-wrapper top" fxLayoutAlign="space-around center">
            <ng-container [ngTemplateOutlet]="selectiveTableActionBtns"></ng-container>
        </div>
    </div>
    <div class="table-wrapper">
        <mat-table
            #table
            [dataSource]="dataSource"
            matSortActive="created_at"
            matSortDirection="desc"
            matSort
            [class.mobileView]="isMobile$ | async"
            multiTemplateDataRows
        >
            <ng-container matColumnDef="select">
                <mat-header-cell *matHeaderCellDef>
                    <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="$event ? masterToggle() : null; showActionHeader = $event.checked"
                        [checked]="false"
                        [indeterminate]="false"
                        [aria-label]="checkboxLabel()"
                    >
                    </mat-checkbox>
                </mat-header-cell>
                <mat-cell *matCellDef="let notification">
                    <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(notification) : null; showActionHeader = $event.checked"
                        [checked]="selection.isSelected(notification)"
                        [aria-label]="checkboxLabel(notification)"
                    >
                    </mat-checkbox>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="created_at">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@date"> Date </mat-header-cell>
                <mat-cell *matCellDef="let notification">
                    <span class="date-mobile" *ngIf="notification.created_at && !(isMobile$ | async)">{{
                        notification.created_at | kdDateFormat : 'YYYY-MM-DD'
                    }}</span>
                    <ng-container *ngIf="isMobile$ | async">
                        <div class="first-row-mobile">
                            <div
                                [matTooltip]="(notification.scope_id | notificationType)?.toolTip"
                                [ngStyle]="{ color: (notification.scope_id | notificationType)?.color }"
                                fxLayoutGap="5px"
                                fxLayoutAlign="start center"
                            >
                                <mat-icon>{{ (notification.scope_id | notificationType)?.icon }}</mat-icon>
                            </div>
                            <div class="suject">
                                <span *ngIf="notification.subject">{{ notification.subject }}</span>
                            </div>
                            <button
                                class="tools-btn orange"
                                mat-icon-button
                                (click)="$event.stopPropagation(); importantNotification(notification)"
                            >
                                <mat-icon>{{ notification.important ? 'star' : 'star_border' }}</mat-icon>
                            </button>
                            <button
                                *ngIf="isMobile$ | async"
                                (click)="$event.stopPropagation(); expandRow(notification)"
                                matTooltip="View"
                                mat-icon-button
                                i18n-matTooltip="@@view"
                            >
                                <mat-icon>{{ expandedRow === notification ? 'expand_less' : 'expand_more' }}</mat-icon>
                            </button>
                        </div>
                    </ng-container>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="author">
                <mat-header-cell *matHeaderCellDef i18n="@@from"> From </mat-header-cell>
                <mat-cell *matCellDef="let notification">
                    <div
                        *ngIf="notification.author$ | async; let loadedAuthor"
                        class="user-chip"
                        [ngClass]="{
                            'dark-blue-bg': $any(loadedAuthor).role_value === 'user',
                            'green-bg': $any(loadedAuthor).role_value !== 'user'
                        }"
                        fxLayout="row"
                        fxLayoutAlign="start center"
                    >
                        <mat-icon>{{ $any(loadedAuthor).role_value | userRoleIcon }}</mat-icon>
                        <span>{{ $any(loadedAuthor).name }}</span>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="recipients">
                <mat-header-cell *matHeaderCellDef i18n="@@to"> To </mat-header-cell>
                <mat-cell class="inset-shadow" *matCellDef="let notification">
                    <!-- <div class="shadow-maker"></div> -->

                    <div class="chips-wrapper">
                        <mat-chip-list class="middle-details">
                            <mat-chip
                                [ngClass]="{
                                    'dark-blue-bg': recipient.role_value === 'user',
                                    'green-bg': recipient.role_value !== 'user'
                                }"
                                class="cat-chip-item user-chip"
                                *ngFor="let recipient of notification.recipients"
                                ><mat-icon>{{ recipient.role_value | userRoleIcon }}</mat-icon>
                                <span>{{ recipient.fname }} {{ recipient.lname }}</span></mat-chip
                            >
                            <mat-chip class="cat-chip-item group-chip" *ngFor="let group of notification.groups"
                                ><mat-icon>groups</mat-icon> <span>{{ group.name }}</span></mat-chip
                            >
                        </mat-chip-list>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="scope_id">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    <span i18n="@@type">Type</span>
                </mat-header-cell>
                <mat-cell
                    [ngStyle]="{ color: (notification.scope_id | notificationType)?.color }"
                    *matCellDef="let notification"
                >
                    <div
                        [matTooltip]="(notification.scope_id | notificationType)?.toolTip"
                        [ngStyle]="{ color: (notification.scope_id | notificationType)?.color }"
                        fxLayoutGap="5px"
                        fxLayoutAlign="start center"
                    >
                        <mat-icon>{{ (notification.scope_id | notificationType)?.icon }}</mat-icon
                        ><span>{{ (notification.scope_id | notificationType)?.title }}</span>
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="subject">
                <mat-header-cell i18n *matHeaderCellDef> Subject </mat-header-cell>
                <mat-cell *matCellDef="let notification">
                    <span *ngIf="notification.subject">{{ notification.subject }}</span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="badge">
                <mat-header-cell i18n *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let notification">
                    <span class="badge" *ngIf="notification.badge">{{ notification.badge }}</span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="important">
                <mat-header-cell *matHeaderCellDef i18n="@@important"> Important </mat-header-cell>
                <mat-cell *matCellDef="let notification">
                    <button
                        class="tools-btn orange"
                        mat-icon-button
                        (click)="$event.stopPropagation(); importantNotification(notification)"
                    >
                        <mat-icon>{{ notification.important ? 'star' : 'star_border' }}</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="tools">
                <mat-header-cell i18n *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let notification">
                    <ng-content select="[tools]"></ng-content>
                    <button
                        *ngIf="notification.downloads"
                        mat-icon-button
                        matTooltip="Click to download"
                        (click)="$event.stopPropagation(); download(notification)"
                        i18n-matTooltip="@@clickToMakeDownload"
                    >
                        <mat-icon>save_alt</mat-icon>
                    </button>
                    <button
                        *ngIf="!(isMobile$ | async)"
                        (click)="$event.stopPropagation(); expandRow(notification)"
                        matTooltip="View"
                        mat-icon-button
                        i18n-matTooltip="@@view"
                    >
                        <mat-icon>{{ expandedRow === notification ? 'expand_less' : 'expand_more' }}</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="expandedDetail">
                <mat-cell *matCellDef="let notification" [attr.colspan]="columns.length">
                    <div
                        class="example-element-detail"
                        [@detailExpand]="notification == expandedRow ? 'expanded' : 'collapsed'"
                    >
                        <ng-container *ngIf="expandedRow == notification">
                            <ng-content></ng-content>
                        </ng-container>
                    </div>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
            <mat-row
                *matRowDef="let notification; columns: columns"
                [class.example-expanded-row]="expandedRow === notification"
                [class.res-expanded-row]="expandedCell === notification"
                [class.unread-msg]="
                    !notification.read_at &&
                    !$any(authUser | async)?.isMessageAuthor(notification) &&
                    msgContextType !== MessageContextTypes.DRAFT
                "
                (click)="expandRow(notification)"
                class="example-element-row"
            ></mat-row>
            <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></mat-row>
        </mat-table>
    </div>
</div>
<mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>

<ng-template #loadingTemp>
    <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="center" class="spinner-container">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
<ng-template #tableCellActionBtns let-notification="notification"> </ng-template>
<ng-template #selectiveTableActionBtns>
    <button class="tools-btn orange" mat-raised-button (click)="$event.stopPropagation()">
        <mat-icon>mail</mat-icon>
        <span class="btn-label" i18n="@@markedAsRead">Marked as read</span>
    </button>
    <button class="tools-btn orange" mat-raised-button (click)="$event.stopPropagation(); makeMsgImportant(true)">
        <mat-icon>star</mat-icon>
        <span class="btn-label" i18n="@@markAsImportant">Mark as important</span>
    </button>

    <button class="tools-btn orange" mat-raised-button (click)="$event.stopPropagation(); makeMsgImportant(false)">
        <mat-icon>star_border</mat-icon>
        <span class="btn-label" i18n="@@markAsNNotImportant">Mark as not important</span>
    </button>
    <button class="tools-btn orange" mat-raised-button (click)="$event.stopPropagation(); deleteMsg()">
        <mat-icon>delete</mat-icon>
        <span class="btn-label" i18n="@@delete">Delete</span>
    </button>
</ng-template>

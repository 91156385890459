import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { AuthService } from '@klickdata/core/auth';
import { FormHelper } from '@klickdata/core/form';
import { MediaComponent } from '@klickdata/core/media/src/media.component';
import { MobileService, SideNaveDataTypes } from '@klickdata/core/mobile';
import { AppScope, Resource, ResourceTag } from '@klickdata/core/resource';
import { ResourceItem, ResourceItemTypes } from '@klickdata/core/resource-item';
import { combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    first,
    map,
    shareReplay,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { ResourceBuilderService } from '../resource-builder.service';
import { ResourceItemDirective } from './resource-item.directive';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ResourceListingSheetComponent } from '../../resource-listing-sheet/resource-listing-sheet.component';
import { TagService } from '@klickdata/core/resource/src/tag/tag.service';

@Component({
    selector: 'app-resource-item-form',
    templateUrl: './resource-item-form.component.html',
    styleUrls: ['./resource-item-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceItemFormComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(MatExpansionPanel) questionExtrasPanel: MatExpansionPanel;
    @ViewChild('title') titleInput: ElementRef;
    @ViewChild('itemDirective') itemDirective: ResourceItemDirective;
    @Input()
    public item: ResourceItem;

    @Input()
    public itemGroup: FormGroup;

    @Input()
    public usePoints = true;
    @Input()
    public index: number;
    public editable: boolean;
    public ItemTypes = ResourceItemTypes;
    public destroy: Subject<boolean> = new Subject<boolean>();
    public displayDesc = false;
    public questionLoaded: boolean;
    public selection: number[] = [];
    public selectedResource$: Observable<Resource>;
    AppScope = AppScope;
    @ViewChild('app_media') mediaCompRef: MediaComponent;
    public resourceTags$: Observable<ResourceTag[]>;

    constructor(
        protected builderService: ResourceBuilderService,
        protected changeRef: ChangeDetectorRef,
        protected auth: AuthService,
        protected tagService: TagService,
        protected bottomSheet: MatBottomSheet,
        protected mobileService: MobileService
    ) {}

    ngOnInit() {
        this.itemGroup
            .get('question')
            .valueChanges.pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.destroy))
            .subscribe((question) => {
                this.questionLoaded = !!question;
                this.checkQuestionEditMode();
                this.changeRef.markForCheck();
            });

        combineLatest([this.auth.getUser().pipe(first()), this.builderService.getResource()])
            .pipe(
                map(([user, resource]) => (resource ? user.canEdit(resource) : true)),
                takeUntil(this.destroy)
            )
            .subscribe((editable) => {
                this.editable = editable;
            });

        this.builderService
            .getItems()
            .pipe(takeUntil(this.destroy))
            .subscribe((items) => (this.selection = items.map((item) => item.child_resource_id)));
    }

    ngAfterViewInit() {
        this.titleInput?.nativeElement?.focus();
        this.selectedResource$ = this.itemDirective.onResourceExist.asObservable().pipe(
            shareReplay(),
            filter((res) => !!res),
            switchMap((resource) => {
                this.resourceTags$ = !resource.tags_attached
                    ? EMPTY
                    : !!resource.tags?.length
                    ? of(resource.tags.map((tag) => new ResourceTag(tag)))
                    : this.tagService.getResourceTags(resource.id);
                return of(resource);
            }),
            tap((resource) => this.updateItemGroup(resource))
        );
    }

    checkQuestionEditMode() {
        if (!this.editable) {
            this.itemGroup.controls.name.disable();
            const question = <FormGroup>this.itemGroup.get('question');
            question.controls.description.disable({ emitEvent: false });
            question.controls.learning_field.disable({ emitEvent: false });
            question.controls.references.disable({ emitEvent: false });
            question.controls.question_type_value.disable({ emitEvent: false });
            question.controls.other_alternative?.disable({ emitEvent: false });
            question.controls.randomize_alternatives.disable({ emitEvent: false });
        }
    }

    updateItemGroup(resource: Resource): void {
        this.itemGroup.patchValue({
            title: resource.title,
            description: resource.description,
        });
        if (this.itemGroup.value.item_type_value === ResourceItemTypes.MATERIAL) {
            this.itemGroup.patchValue({
                instructions: resource.instructions,
            });
        }
    }

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
    public showTagResources(tag: ResourceTag) {
        this.bottomSheet.open(ResourceListingSheetComponent, {
            data: {
                id: tag.id,
                label: tag.name,
                scope_id: AppScope.TAGS,
            },
            panelClass: 'sheet-wrapper',
        });
    }
    public removeImage(): void {
        this.item.media_id = null;
        this.item.media.url = null;
        this.itemGroup.get('question').patchValue({
            img_url: null,
            media_id: null,
        });
        FormHelper.markForm(this.itemGroup);
    }

    public toggleDisplayDesc() {
        this.displayDesc = !this.displayDesc;
    }

    public getQsExtraInfo(question: AbstractControl) {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.QUESTION_EXTRA_INFO,
            data: {
                type: this.index,
                value: question.value,
                editable: this.editable,
            },
        });

        this.mobileService
            .getSideNavResponseData()
            .pipe(
                filter((data) => data.type === this.index),
                takeUntil(this.destroy),
                map((data) => data.info)
            )
            .subscribe((info) => {
                if (info) {
                    question.get('description')?.patchValue(info.description);
                    question.get('learning_field')?.patchValue(info.learning_field);
                    question.get('references')?.patchValue(info.references);
                    FormHelper.markForm(<FormGroup>question);
                    FormHelper.markForm(this.itemGroup);
                    this.changeRef.markForCheck();
                }
            });
    }
}
